<template>
  <div>
    <loading
      :active.sync="isLoading"
      background-color="#332abb"
      :opacity="0.99"
      color="#fff"
      :is-full-page="fullPage"
    ></loading>

    <ModalBagisYap ref="modalBagisYap"></ModalBagisYap>
    <ModalKampanyaOlustur ref="modalKampanyaOlustur"></ModalKampanyaOlustur>
    <ModalHediyeKarti ref="modalHediyeKarti"></ModalHediyeKarti>
    <modalGallery ref="modalGallery"></modalGallery>
    <MainBanner
      @clicked="showModal"
      :Title="this.pageContent.page && this.pageContent.page[0].title"
      :Desc="this.pageContent.page && this.pageContent.page[0].content"
      ButtonText="Bağış Yap"
      OverlapImage="/svg/banner-bilim-seferberligi.svg"
      TextPosition="between"
      Height="middle"
    />

    <div class="main-continer">
      <section class="container bilim-seti-info section-high" id="bilim-seti">
        <div class="d-flex flex-column flex-lg-row align-items-center">
          <div v-if="this.pageContent">
            <div v-html="this.pageContent.page[1].content"></div>

            <ButtonRounded
              class="mt-4"
              Text="Bilim Setini İncele"
              color="blue"
              @click.native="showModalGallery"
              :isRouting="false"
            />
          </div>

          <div
            class="
              bilim-seti-img
              d-none d-lg-block
              gs_reveal gs_reveal_fromRight
            "
          >
            <img src="/svg/bilim-seti.svg" />
          </div>
        </div>
      </section>

      <section class="section-high bgPurpleLight">
        <BagisSec
          class="container"
          :Title="bagisSecTitle"
         
          @showBirBagisYap="showModal"
          @showKampanyaOlustur="modalKampanyaOlustur"
          @showHediyeKarti="modalHediyeKarti"
        />
      </section>

      <section class="toplanan-bagis section-padding container">
        <div class="row align-items-center justify-content-between" v-if="this.pageContent.page">
          <div class="col-12 col-md-5 text-center text-md-left" v-if="this.infographic.total_donation">
            <h4 v-html="this.pageContent.page[3].title"></h4>
            <h2 v-html="this.infographic.student.toLocaleString('en')"></h2>
            <p v-html="this.pageContent.page[3].content"></p>
            <div class="logo-wraper">
              <a href="https://meb.gov.tr/" target="_blank"><img src="/svg/meb-logo.svg"></a>
              <a href="https://www.yga.org.tr/" target="_blank"><img src="/img/yga-logo.png"></a>
            </div>
          </div>

          <div class="col-6 d-none d-md-flex">
            <img src="/svg/toplanan-bagis.svg" />
          </div>
        </div>
      </section>

      <section class="section-padding container">
        <ToplananBagisInfographic
          :countTeacher="this.infographic.teacher"
          :countSchool="this.infographic.school"
          :countStudent="this.infographic.student"
        />
      </section>
      <section id="bilim-seferberligi-danisma-kurulu" class="section-padding container section-high h-max mt-5" v-if="this.pageContent">
        <div class="floating-carousel-info text-center mw-70" v-html="this.pageContent.page[4].content"></div>

        <div class="swiper-carousel swiper-destekciler swiper-small container position-relative"
          v-if="mainSupporters.length > 0"
        >
          <div class="swiper-wrapper">
            <div class="swiper-slide text-center"
              v-for="(main, index) in mainSupporters"
              :key="index"
            >
              <img class="img-wrapper" :src="main.image" />
              <h3 class="name">{{ main.name_surname }}</h3>
              <p class="title">{{ main.title }}</p>
            </div>
          </div>

          <!-- <div class="swiper-button swiper-button-destekciler swiper-button-next"></div>
          <div class="swiper-button swiper-button-destekciler swiper-button-prev"></div> -->
          <!-- <div class="swiper-pagination"></div> -->
        </div>
      </section>

      <section
        class="parallax-section gray-section section-padding section-high"
        id="neden-bilim-seferberligi"
      >
        <div
          class="
            container
            d-flex
            flex-column-reverse flex-lg-row
            align-items-center
            justify-content-between
          "
        >
          <div class="text-center text-md-left col-md-5 ml-0" v-if="this.pageContent">
            <div v-html="this.pageContent.page[5].content"></div>

            <img src="/img/untitled-1.png" class="d-block d-md-none" />
          </div>

          <div class="parallax-img-wrapper col-md-6 d-none d-md-flex align-items-center justify-content-center">
            <img class="prl-0" src="/img/parallax/02/okul-bagis.svg" />
            <img class="down-1 prl-1" src="/img/parallax/02/1.png" />
            <img class="down-2 prl-2" src="/img/parallax/02/2.png" />
            <img class="up prl-3" src="/img/parallax/02/3.png" />
          </div>
        </div>
      </section>

      <section
        class="parallax-section section-padding section-high"
        id="yga-hakkinda"
      >
        <div
          class="
            container
            d-flex
            flex-column-reverse flex-lg-row
            align-items-center
            justify-content-between
          "
        >
          <div
            class="
              parallax-img-wrapper
              col-md-6
              d-none d-md-flex
              align-items-center
              justify-content-center
            "
          >
            <img class="prl-0" src="/img/parallax/01/hands.svg" />
            <img class="down-1 prl-1" src="/img/parallax/01/1.png" />
            <img class="down-2 prl-2" src="/img/parallax/01/2.png" />
            <img class="up prl-3" src="/img/parallax/01/3.png" />
          </div>

          <div class="text-center text-md-right col-md-5 ml-0" v-if="this.pageContent">
            <img src="/img/yga-logo.png" alt="YGA Logo" class="mr-2" />
            <div v-html="this.pageContent.page[6].content"></div>

            <img src="/img/untitled-1.png" class="d-block d-md-none" />
          </div>
        </div>
      </section>

      <section
        class="parallax-section gray-section section-padding section-high"
        id="nasil-fayda-saglarim"
      >
        <div
          class="
            container
            d-flex
            flex-column-reverse flex-lg-row
            align-items-center
            justify-content-between
          "
        >
          <div class="text-center text-md-left col-md-5 ml-0" v-if="this.pageContent">
            <div v-html="this.pageContent.page[7].content"></div>

            <img src="/img/howtohands.svg" class="d-block d-md-none" />

            <ButtonRounded
              class="mt-4"
              Text="Bilim Şenlikleri"
              color="blue"
              :isRouting="true"
              Link="/hikayelerimiz#bilim-senlikleri"
            />
          </div>

          <div
            class="
              parallax-img-wrapper
              col-md-6
              d-none d-md-flex
              align-items-center
              justify-content-center
            "
          >
            <img class="" src="/img/howtohands.svg" />
          </div>
        </div>
      </section>

      <section
        class="parallax-section section-padding section-high"
        id="nasil-calisir"
      >
        <div
          class="
            container
            d-flex
            flex-column-reverse flex-lg-row
            align-items-center
            justify-content-between
            mt-auto
          "
        >
          <div
            class="
              parallax-img-wrapper
              col-md-6
              d-none d-md-flex
              align-items-center
              justify-content-center
            "
          >
            <img class="prl" src="/img/howto.png" />
          </div>

          <div class="text-center text-md-right col-md-5 ml-0" v-if="this.pageContent">
            <div 
            ></div>
                <div><h1 class="mb-3">Hikayeye<br>
                Ortak Ol!</h1>

                <p class="mb-5 mb-md-0">Bilim Seti gönderimi yapılan okullardaki öğretmenler Online Eğitim Platformu’ndaeklenir.</p>

                <p class="mb-5 mb-md-0">Bu platformda güncel deneyler ve bilim içerikleri paylaşılır.</p></div>
            <img src="/img/howto.png" class="d-block d-md-none" />

            <!-- <ButtonRounded
              class="mt-4 float-right"
              Text="Hikayelerimiz"
              color="blue"
              :isRouting="false"
              to="/hikayelerimiz"
            /> -->
            <router-link
              class="
                button-rounded
                d-flex
                align-items-center
                justify-content-center
                mt-4
                float-right
                blue
              "
              to="/hikayelerimiz"
              >Hikayelerimiz</router-link
            >
          </div>
        </div>

        <p class="footnote text-center mt-md-auto mt-3">
          Bağışçı hakları beyannamesini
          <a
            class="text-underline"
            href="/pdf/bagiscihaklaribeyannamesi.pdf"
            target="_blank"
            >buradan</a
          >
          inceleyebilirsiniz.
        </p>
      </section>

      <section
        class="basinda-bilim gray-section section-padding section-high"
        id="basinda-biz"
      >
        <div class="container text-center">
          <h4 class="mb-3">Basında Bilim Seferberliği</h4>
        </div>

        <div class="container">
          <div
            class="
              swiper-carousel swiper-basinda-bilimseferberligi swiper-small
              position-relative
              py-5
            "
            v-if="newsData.length > 0"
          >
            <div class="swiper-wrapper">
              <div
                class="swiper-slide text-center"
                v-for="(news, index) in newsData"
                :key="index"
              >
                <a :href="news.url" target="_blank">
                  <div class="logo-wrapper">
                    <img :src="news.image" /></div
                ></a>
                <h3 class="name">{{ news.title }}</h3>
                <p class="title">
                  {{ news.desc }}
                </p>
              </div>
            </div>

            <div class="swiper-button swiper-button-destekciler swiper-button-next d-none d-md-flex"></div>
              <div class="swiper-button swiper-button-destekciler swiper-button-prev d-none d-md-flex"></div>
            <!-- <div class="swiper-pagination"></div> -->
          </div>
        </div>
      </section>

      <FooterLanding />
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
var VueScrollTo = require("vue-scrollto");
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import {
  Swiper,
  Scrollbar,
  EffectCoverflow,
  SwiperClass,
  Pagination,
  Navigation,
  Mousewheel,
  Autoplay,
  EffectFade,
} from "swiper";
Swiper.use([
  Navigation,
  Pagination,
  Scrollbar,
  EffectCoverflow,
  Mousewheel,
  Autoplay,
  EffectFade,
]);
import "swiper/swiper-bundle.css";

import ButtonRounded from "@/components/ButtonRounded";
import MainBanner from "@/components/MainBanner";
import FooterLanding from "@/components/FooterLanding";
import ModalBagisYap from "@/components/ModalBagisYap";
import ModalKampanyaOlustur from "@/components/ModalKampanyaOlustur";
import ModalHediyeKarti from "@/components/ModalHediyeKarti";
import BagisSec from "@/components/BagisSec";
import FloatingCard from "@/components/FloatingCard";
import ToplananBagisInfographic from "@/components/ToplananBagisInfographic";
import MultipleCarousel from "@/components/MultipleCarousel";
import ModalGallery from "@/components/ModalGallery";
export default {
  name: "BilimSeferberligi",
  components: {
    ButtonRounded,
    MainBanner,
    FooterLanding,
    ModalBagisYap,
    Loading,
    BagisSec,
    FloatingCard,
    ToplananBagisInfographic,
    ModalKampanyaOlustur,
    ModalHediyeKarti,
    MultipleCarousel,
    ModalGallery,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      bagisSecTitle: "",
      bagisSecDesc: "",
      teacherData: {},
      studentData: {},
      newsData: {},
      mainSupporters: {},
      infographic: "",
      pageContent: ''
    };
  },
    metaInfo() {
    return {
      title: 'Bilim Seferberliği - İmkanları sınırlı, hayalleri sınırsız çocukları bilim ile buluştur.',
      meta: [
        {
          vmid: "description",
          name: "description",
          //content: this.metaData.description,
        },
        // {name: 'keywords', content: this.meta_data.meta_keywords},
        // {property: 'og:image', content: this.meta_data.meta_image},
        { property: "og:title", content: 'Bilim Seferberliği - İmkanları sınırlı, hayalleri sınırsız çocukları bilim ile buluştur.' },
        { property: "og:description", content: 'Okullara geri dönen imkanları sınırlı, hayalleri sınırsız çocuklarımıza Bilim Seti ulaştırılması için destek ol!' },
        { property: "og:url", content: window.location.href },
        { name: "twitter:title", content: 'Bilim Seferberliği - İmkanları sınırlı, hayalleri sınırsız çocukları bilim ile buluştur.' },
        { name: "twitter:description", content: 'Okullara geri dönen imkanları sınırlı, hayalleri sınırsız çocuklarımıza Bilim Seti ulaştırılması için destek ol!' },
        { name: "twitter:url", content: window.location.href},
        // {name: 'twitter:image', content: this.meta_data.meta_image},
        // {property: 'og:image:width', content: '540'},
        // {property: 'og:image:height', content: '282'},
      ],
    };
  },
  mounted() {
    function scrollToAnchor(url) {
      var urlHash = url.split("#")[1];
      if (urlHash && $("#" + urlHash).length) {
        $("html").animate(
          {
            scrollTop: $("#" + urlHash).offset().top - 60,
          },
          500
        );
      }
    }

    $(document).ready(function () {
      scrollToAnchor(window.location.href);
    });

    $(".same-links a").click(function (event) {
      event.preventDefault(); // stop the browser from snapping to the anchor
      scrollToAnchor(event.target.href);
    });
    this._swiperInit();
    // this._floatingParallax();
    this._scrollParallax();
    this.getStories();
    this.getNews();
    this.getSupporters();
    this.getInfographic();
    if (this.$router.currentRoute["hash"]) {
      VueScrollTo.scrollTo(this.$router.currentRoute["hash"], 500);
    }
    this.getPageContent("bilim-seferberligi");
  },
  beforeRouteUpdate(to, from, next) {
    if (this.$router.currentRoute["hash"]) {
      VueScrollTo.scrollTo(this.$router.currentRoute["hash"], 500);
    }
    this._swiperInit();
  },
  methods: {
    getPageContent(page) {
      this.$api.getPageContent(page).then((response) => {
        this.pageContent = response;
        this.bagisSecTitle = this.pageContent.page[2].content.replace("</h1>", "</h1>#").split("#")[0];
        this.bagisSecDesc = this.pageContent.page[2].content.replace("</h1>", "</h1>#").split("#")[1];
      });
    },
    getInfographic() {
      this.$api.getInfographic().then((response) => {
        this.infographic = response;
      });
    },
    showModal() {
      this.$refs.modalBagisYap.openModal();
      this.$refs.modalBagisYap.getSwiper();
    },
    modalKampanyaOlustur() {
      this.$refs.modalKampanyaOlustur.openModal();
      this.$refs.modalKampanyaOlustur.getSwiper();
    },
    showModalGallery() {
      this.$refs.modalGallery.openModal();
    },
    getStories() {
      this.$api.getStories().then((response) => {
        this.teacherData = response.teacher;
        this.studentData = response.student;
      });
    },
    getNews() {
      this.$api.getNews().then((response) => {
        this.newsData = response;
      });
    },
    modalHediyeKarti() {
      this.$refs.modalHediyeKarti.openModal();
      this.$refs.modalHediyeKarti.getSwiper();
    },
    getSupporters() {
      this.$api.getSupporters().then((response) => {
        this.mainSupporters = response.advisory_board;

        setTimeout(() => {
          this._swiperInit();
        }, 300);
      });
    },
    _swiperInit() {
      const swiperBasinda = new Swiper(".swiper-basinda-bilimseferberligi", {
        loop: false,
        spaceBetween: 30,
        centeredSlides: false,
        navigation: {
          nextEl: ".swiper-basinda-bilimseferberligi .swiper-button-next",
          prevEl: ".swiper-basinda-bilimseferberligi .swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        
        breakpoints: {
          567: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 4,
          },
        },
      });
      const swiperBilimSenlikleri = new Swiper(".swiper-destekciler", {
        //slidesPerView: 5,
        spaceBetween: 20,
        // pagination: {
        //   el: '.swiper-pagination',
        // },
        breakpoints: {
          640: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 3,
          },
          1024: {
            slidesPerView: 4,
          },
        },
        // pagination: {
        //   el: ".swiper-pagination",
        // },
      });
    },
    _scrollParallax() {
      gsap.utils.toArray(".parallax-img-wrapper").forEach((section, index) => {
        const down1 = section.querySelector(".down-1");
        const down2 = section.querySelector(".down-2");
        const up = section.querySelector(".up");
        const x = section.querySelector(".parallax-img-wrapper");

        gsap.to(down1, {
          scrollTrigger: {
            trigger: section,
            // markers:true,
            start: "top 80%",
            end: "bottom 20%",
            scrub: 1,
            // toggleActions: "play reset play complete",
            // ease:'linear'
          },
          y: 100,
          x: 20,
        });

        gsap.to(down2, {
          scrollTrigger: {
            trigger: section,
            // markers:true,
            scrub: 1,
            start: "top 80%",
            end: "bottom 20%",
          },
          y: 75,
          x: -20,
        });

        gsap.to(up, {
          scrollTrigger: {
            trigger: section,
            // markers:true,
            scrub: 1,
            start: "top 80%",
            end: "bottom 20%",
          },
          y: -75,
          x: 30,
        });
      });
    },
  },
};
</script>
<style lang="scss">
.mw-70 {
  max-width: 70%;
}
h4 {
  font-size: 22px;
  font-weight: 600;
}
@media (max-width: 567px) {
  h4 {
    font-size: 22px;
    font-weight: 600;
  }
  .swiper-basinda-bilimseferberligi {
    .logo-wrapper {
      img {
        max-width: 100%;
      }
    }
    h3 {
      font-size: 22px;
      font-weight: 500;
    }
    p {
      font-size: 16px;
    }
  }
}
</style>